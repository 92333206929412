angular.module('icn').config([
  '$routeProvider',
  '$locationProvider',
  function ($routeProvider, $locationProvider) {
    $routeProvider

      // Login
      .when('/login/unlocked', {
        templateUrl: 'areas/login/templates/unlocked.html',
        isPublic: true,
      })

      .when('/login/:referral?', {
        controller: 'LoginCtrl',
        templateUrl: 'areas/login/login/login_index.html',
        title: 'Login',
        isPublic: true,
      })

      .when('/mfa_verify?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
        isPublic: true,
      })

      .when('/mfa_register', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      .when('/forgot_password?', {
        controller: 'ForgotPasswordCtrl',
        templateUrl: 'areas/login/forgot_password/forgot_password.html',
        title: 'Forgot Password',
        isPublic: true,
      })

      .when('/reset_password?', {
        controller: 'ResetPasswordCtrl',
        templateUrl: 'areas/login/reset_password/reset_password.html',
        title: 'Reset Password',
        isPublic: true,
      })

      .when('/set_password', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      .when('/logout', {
        template: '&nbsp;',
        controller: 'LogoutCtrl',
      })

      .when('/register?', {
        controller: 'RegisterCtrl',
        templateUrl: 'areas/register/register/register_index.html',
        isPublic: true,
      })

      // Funds
      .when('/funds/:id', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

    .when('/client_management/:paf_id?/:ria_id?', {
      controller: 'ReactRedirectCtrl',
      template: '',
      isReactPage: true
    })

      .when('/export_history', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

    .when('/investment_management/:paf_id?', {
      controller: 'ReactRedirectCtrl',
      template: '',
      isReactPage: true
    })

    .when('/client_management/:paf_id?/team_code/:team_code?', {
      controller: 'ReactRedirectCtrl',
      template: '',
      isReactPage: true
    })

      // Rails Admin
      .when('/admin', {
        template: '&nbsp;',
        controller: function () {
          window.location.href = '/admin';
        },
      })

      .when('/publisher_tool', {
        template: '&nbsp;',
        controller: function () {
          window.location.href = '/publisher_tool';
        },
      })

      .when('/uploader_tool', {
        template: '&nbsp;',
        controller: function () {
          window.location.href = '/uploader_tool';
        },
      })

      .when('/sftp_service', {
        template: '&nbsp;',
        controller: function () {
          window.location.href = '/sso/saml/idp/auth?partner=sftp_service';
        },
      })

      .when('/reconciliation_dashboard', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })


      // Signature Vendor Library Templates Admin
      .when('/library_templates', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      // WLP Admin
      .when('/wlp_admin', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      // Private Access Funds
      .when('/private_access_funds', {
        redirectTo: '/investment_opportunities',
      })

      .when('/private_equity_funds', {
        redirectTo:
          '/investment_opportunities/params?category=Private%20Credit&category=Private%20Equity&category=Real%20Assets&sort_type=default&view_type=tiles',
      })

      .when('/hedge_funds', {
        redirectTo:
          '/investment_opportunities/params?category=Hedge%20Fund&sort_type=default&view_type=tiles',
      })

      // Direct Deal Funds
      .when('/direct_deal_funds', {
        redirectTo: '/investment_opportunities',
      })

      .when('/ai_funds', {
        redirectTo:
          '/investment_opportunities/params?investor_qualification=Accredited%20Investor&sort_type=default&view_type=tiles',
      })

      .when('/compliance/investor_search', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/compliance/compliance_reporting', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/compliance/form_pf_classification', {
        controller: 'FormPfClassificationCtrl',
        templateUrl:
          'areas/compliance/tabs/form_pf_classification/form_pf_classification.html',
      })
      //New AML Approvals
      .when(
        '/aml_approval_document_status_dashboard/reversion/reverted_documents/:investment_id',
        {
          controller: 'ReactRedirectCtrl',
          template: '',
          isReactPage: true,
        }
      )
      .when('/aml_approval_document_status_dashboard/:pafId?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      .when(
        '/aml_approval_document_status_dashboard/:pafId/send_gp_certificate',
        {
          controller: 'ReactRedirectCtrl',
          template: '',
          isReactPage: true,
        }
      )

      .when('/aml_approval_document_status_dashboard/:pafId/:investmentId', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      // Paf Admin - PE
      .when('/paf_admin/pe/funds/:private_access_fund_id?', {
        controller: 'PafAdminFundsCtrl as vm',
        templateUrl: 'areas/paf_admin/tabs/pe/funds/pages/funds/funds.html',
      })

      .when('/paf_admin/hf/funds/:private_access_fund_id?', {
        controller: 'PafAdminFundsCtrl as vm',
        templateUrl: 'areas/paf_admin/tabs/hf/funds/pages/funds/funds.html',
      })

      // Required Documents
      .when('/required_documents', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      // Investor Relations
      .when('/investor_relations/investor_search', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investor_relations/investor_search/:cardId', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when(
        '/investor_relations/investor_search/detail/:contactCardId/:fundId', {
          controller: 'ReactRedirectCtrl',
          template: '',
          isReactPage: true,
        }
      )
      .when(
        '/investor_relations/investment_contact_details/edit_contact_details',
        {
          controller: 'ReactRedirectCtrl',
          template: '',
          isReactPage: true,
        }
      )

      .when('/investor_relations/mass_emails', {
        controller: 'InvestorRelationsMassEmailsCtrl',
        templateUrl:
          'areas/investor_relations/tabs/mass_emails/mass_emails.html',
        title: 'Mass Emails',
      })
      .when('/subscription_workflow_status', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      // User Management
      .when('/user_management', {
        controller: 'UserManagementCtrl',
        templateUrl:
          'areas/user_management/tabs/user/pages/user_management/user_management.html',
      })
      // User Management
      .when('/user_management/user_export', {
        controller: 'UserManagementUserExportCtrl',
        templateUrl:
          'areas/user_management/tabs/user/pages/export/user_export.html',
      })

      .when('/user_management/reverse_solicitation', {
        controller: 'UserManagementReverseSolicitationCtrl',
        templateUrl:
          'areas/user_management/tabs/user/pages/reverse_solicitation/reverse_solicitation.html',
      })

      .when('/user_management/:user_id/referral_codes', {
        controller: 'UserManagementReferralCodesCtrl',
        templateUrl:
          'areas/user_management/user_management_referral_codes.html',
      })

      .when('/user_management/:user_id/private_access_funds', {
        controller: 'UserManagementAccessFundsCtrl',
        templateUrl: 'areas/user_management/user_management_access_funds.html',
      })

      // User Settings
      .when('/settings', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      // User Profile
      .when('/profile', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      // Profile
      .when('/new_issues_profile/investor_profile/step/:id?', {
        controller: 'NewIssuesProfileCtrl',
        templateUrl: 'areas/no_tab/new_issues_profile/new_issues_profile.html',
      })

      // Agreements Signing
      .when('/agreement_signing', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      .when('/disclosures', {
        controller: 'ReactRedirectCtrl',
        templateUrl: '',
        isPublic: true,
        isReactPage: true,
      })

      .when('/terms_of_use', {
        controller: 'TermsOfUseCtrl',
        templateUrl: 'areas/static/terms_of_use.html',
        isPublic: true,
      })

      .when('/terms_of_use/:id', {
        controller: 'TermsOfUseCtrl',
        templateUrl: 'areas/static/terms_of_use.html',
        isPublic: true,
      })

      .when('/privacy_policy', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

      .when('/custom_terms_of_use', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })

    // The ReactRedirectCtrl will prepend the react prefix '/icn_react/static'
    // and refresh the page, effectively redirecting to the react app
    // after checking for terms of service and popups
    // The ReactRedirectCtrl will prepend the react prefix '/icn_react/static'
      // and refresh the page, effectively redirecting to the react app
      // after checking for terms of service and popups
      .when('/doc_center', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/doc_center_v2', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/published_document_export', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_document_manager', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/subdoc_exclusions', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/report_generator_super_admin', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when(
        '/fund_admin/reconciliation/:reconciliationPath?/:groupId?/:period?/:id?/:publishType?',
        {
          controller: 'ReactRedirectCtrl',
          template: '',
          isReactPage: true,
        }
      )
      .when('/aml_suitability_representations', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_admin/statements/:id?/:statementsPath?/:reportId?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_admin/uploader', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_admin/capital_events/:id?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_admin/operations', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_admin/fund_fees/:tab?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_admin/general_ledgers/:tab?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/notification_events', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/report_generator', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/data_dashboard', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investor_profile/:id', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investor_profile/:id/step/:step', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_dashboard', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/reporting/ppm', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/sales_detail/:fundId?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/delegation', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
        impersonationBlocked: true,
      })
      .when('/impersonation', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investment_dashboard', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investment_dashboard/export_history', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investor_relations/subscription_reviewer', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/integration_partners', {
        controller: 'ReactRedirectCtrl',
        template: '',
      })
      .when('/subscriptions_dashboard', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_events_calendar', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_finances/index_masters', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/fund_finances/index_returns', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investor_representatives', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      // Subscription Documents Admin
      .when('/document_definitions', {
        controller: 'ReactRedirectCtrl',
        template: '',
      })
      .when('/admin_search/:searchText?/:selectedTab?/:selectedResultId?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investment_opportunities/:param?', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      // Education and Learning
      .when('/education_dashboard', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/education_log', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/education_settings', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/investment_product/:id/summary', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/course/:id/summary', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      // New Landing Page
      .when('/home', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      // Portfolio Intelligence
      .when('/portfolio_intelligence', {
        template: '&nbsp;',
        controller: function () {
          window.location.href = '/portfolio_intelligence';
        },
      })
      //Pdf Viewer
      .when('/pdf_viewer', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/activity_log', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/architect', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
      .when('/advisor_dashboard', {
        controller: 'ReactRedirectCtrl',
        template: '',
        isReactPage: true,
      })
    .otherwise({redirectTo: '/home'});

    $locationProvider.html5Mode(true);
  },
]);
