angular.module('services').factory "UserAccreditation",
  ($resource, $q, IcnIdentity) ->
    resource = $resource("/api/v1/users/:id/user_accreditations",
      id: "@id"
    ,
      query:
        method: "GET"
        isArray: false
        headers:
          "Butter-Bar": false

      options:
        method: "GET"
        url: "/api/v1/user_accreditations/options"
        isArray: false
        headers:
          "Butter-Bar": false
    )

    all = (id) ->
      resource.query(
        id: id
      ).$promise

    options = ->
      resource.options(
      ).$promise

    {
      all: all
      options: options
    }
